<template>
    <div class="disposer-app-section">
        <div class="section-main">
            <slot></slot>
        </div>

        <div class="section-btns">
            <app-button
                v-if="save"
                class="section-btn red"
                @click="save"
            >
                Save
            </app-button>

            <app-button
                v-if="deleteHandler"
                class="section-btn"
                @click="deleteFunction"
            >
                Delete
            </app-button>
        </div>
    </div>
</template>

<script>
import appButton from '@/components/disposer/app/app-button';
import { defaultConfirmSettings } from '@/utils/disposer/notifications-text-helper';

export default {
    name: 'app-section',

    components: {
        appButton,
    },

    props: {
        save: {
            type: Function,
            default: null,
        },

        deleteHandler: {
            type: Function,
            default: null,
        },
        deleteWarning: {
            type: String,
            default: 'null',
        },
    },

    methods: {
        deleteFunction() {
            const settings = { ...defaultConfirmSettings };


            if (this.deleteWarning) {
                settings.message = this.deleteWarning;
            }
            if (settings.message === 'null') {
                settings.message = 'Are you sure?';
            }

            this.$confirm({
                ...settings,
                callback: confirm => {
                    if (confirm) {
                        this.deleteHandler();
                    }
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.disposer-app-section {
    display: flex;
    justify-content: space-between;

    height: 100%;
    margin-bottom: 10px;
    padding: 20px;

    background-color: #fdfcfc;

    .section-main {
        width: 100%;
    }

    .section-btns {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    .section-btn {
        width: to_rem(100px);
        height: 35px;
        margin-top: 20px;
    }
}
</style>>
